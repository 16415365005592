<template>
  <EventManagementPage
    class="components"
    title="Comps &amp; Will Call"
    help-video="https://youtube.com/embed/Xe1fuCogT_0"
    :breadcrumbs="breadcrumbs"
    @load="onLoad"
  >
    <magic-link-modal ref="magicLinkModal"></magic-link-modal>
    <div class="page-tabs">
      <tab-tote
        :tabs="navTabs"
        :value="navTabSelected"
        v-on:click="onClickNavTab"
      />
      <!-- :tabDisable="[1]" -->

      <div class="divider"/>
    </div>
    <router-view :url="url" @addWillCall="addWillCall" @addComp="addComp"/>
    <DataTable
      :title="this.compsTableData.title"
      endpoint="/comps/get-comps/:event"
      :args="{ event: eventUrl }"
      ref="compsTable"
      :headers="this.compsTableData.columns"
      :custom-columns="['print', 'active']"
    >
      <template v-slot:[`item.print`]="{ item }">
        <v-icon small @click="print(item)">mdi-file-document</v-icon>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-checkbox
          @change="updateComps(item)"
          v-model="item.active"
          :label="item.active ? 'Y' : 'N'"
          hide-details
        ></v-checkbox>
      </template>
    </DataTable>

    <DataTable
      :title="this.willcallTableData.title"
      endpoint="/comps/get-willcall/:event"
      :args="{ event: eventUrl }"
      ref="willcallTable"
      :headers="this.willcallTableData.columns"
      :custom-columns="['slip', 'print']"
    >
      <template v-slot:[`item.slip`]="{ item }">
        <v-icon small @click="viewReceipt(item)">mdi-receipt-text</v-icon>
      </template>
      <template v-slot:[`item.print`]="{ item }">
        <v-icon small @click="print(item)">mdi-file-document</v-icon>
      </template>
    </DataTable>

    <!-- <div class="img-cont">
      <img id="img" src="../../assets/TicketProof.jpg" alt="" />
      <img
        v-if="!whiteLabelId"
        id="willcall"
        src="../../assets/WillCallSlip.jpeg"
        alt=""
      />
      <img
        v-if="whiteLabelId"
        id="willcall"
        src="../../assets/WLWillCallSlip.jpeg"
        alt=""
      />
      <img id="img-ticket-stock" src="../../assets/stockTicket.jpg" alt="" />
      <img id="img-1" src="/img/logos/TicketTote-Logo-Black.png" alt="" />
      <img id="img-2" src="/img/logos/TicketTote-Logo-Black.png" alt="" />
      <canvas id="canvas" style="display: none"></canvas>
      <canvas
        id="icon-canvas"
        width="300"
        height="300"
        style="display: none"
      ></canvas>
    </div> -->

    <PDFViewer
      v-if="pdfData"
      :src="pdfData"
      :file-name="pdfFileName"
      @close="pdfData = null"
      :invoice="isWillCallSlip"
    />
  </EventManagementPage>
</template>

<script>
import Vue from "vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import TableTote from "@/components/TableTote.vue";
import RadioGroup from "@/components/RadioGroup.vue";

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {emailCheck} from "@/helpers/input-formats.js";
import LogoUploader from "../components/imageUploaders/TTButtonImageUploader.vue";
import dateFormat from "dateformat";
import {
  getSaleDate,
  getVenueAccessDate,
  getVenueAccessTime,
  viewTicket,
  viewTicketStock,
  viewWillCall
} from "@/helpers/tickets.js";
import ModalContent from "@/helpers/modals";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import PDFViewer from "@/components/PDFViewer.vue";
import ProcessingModal from "@/components/modals/ProcessingModal.vue";
import EventManagementPage from "../components/EventManagementPage";
import TicketTierSelector from "@/components/TicketTierSelector.vue";
import dayjs from "dayjs";
import CSVImporter from "../components/comps/CSVImporter.vue";
import DataTable from "@/components/DataTable.vue";
import TabTote from "@/components/TabTote.vue";
import MagicLinkModal from "../components/modals/ShowMagicLinkModal.vue";

export default {
  name: "comps",
  head() {
    return {
      title: "Comps / Will Call"
    };
  },
  components: {
    MagicLinkModal,
    EventManagementPage,
    DiyoboInput,
    DiyoboButton,
    TableTote,
    TabTote,
    FontAwesomeIcon,
    RadioGroup,
    LogoUploader,
    Breadcrumbs,
    PDFViewer,
    ProcessingModal,
    TicketTierSelector,
    CSVImporter,
    DataTable
  },

  props: {
    url: String
  },
  data() {
    return {
      navTabs: ["Manual Entry", "Bulk Upload"],
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Comps / Will Call", null]
      ],
      processing: false,
      compsTableData: {
        title: "Complimentary Tickets",
        columns: [
          {text: "Full Name", value: "name"},
          {text: "Email", value: "email"},
          {
            text: "Ticket Tier",
            value: "tier"
          },
          // {
          //   title: "Tier Date",
          //   field: "ticket_date",
          //   width: wcHasNoTierTimes ? "16%" : "8%",
          //   hidden: wcHasNoTierDates
          // },
          // {
          //   title: "Tier Time",
          //   field: "ticket_time",
          //   width: "8%",
          //   hidden: wcHasNoTierTimes
          // },
          // {
          //   text: "Language",
          //   value: "language"
          // },
          // {
          //   text: "Location",
          //   value: "location"
          // },
          {
            text: "Created",
            value: "created"
          },
          {text: "# of Tix", value: "ticket_count"},
          // { text: "Reason", value: "reason" },
          {text: "Active", value: "active"},
          {text: "Tickets", value: "print"}
        ]
      },
      willcallTableData: {
        title: "Will Call Tickets",
        // subtitle:
        //   "Please use 'Receipt' to download will call for customer and 'Print' when they are collecting tickets at the venue.",
        columns: [
          {text: "Full Name", value: "name"},
          {text: "Email", value: "email"},
          // { text: "Phone Number", value: "phone" },
          {
            text: "Ticket Tier",
            value: "tier"
          },
          // {
          //   title: "Tier Date",
          //   field: "ticket_date",
          //   width: wcHasNoTierTimes ? "16%" : "8%",
          //   hidden: wcHasNoTierDates
          // },
          // {
          //   title: "Tier Time",
          //   field: "ticket_time",
          //   width: "8%",
          //   hidden: wcHasNoTierTimes
          // },
          {
            text: "Language",
            value: "language"
          },
          {
            text: "Location",
            value: "location"
          },
          {
            text: "Created",
            value: "created"
          },
          {text: "# of Tix", value: "ticket_count"},
          {text: "Slip", value: "slip"},
          {text: "Tickets", value: "print"}
        ]
      },
      eventUrl: this.$route.params.url,
      pdfData: null,
      pdfFileName: "",
      isWillCallSlip: false,
      eventDateID: null,
      whiteLabelId: ""
    };
  },
  async mounted() {
    let isReserved = false;
    await this.$axios
      .get(`/events/is-reserved-event/${this.$route.params.url}`)
      .then(response => {
        isReserved = response.data.isReservedEvent;
      });

    if (isReserved) {
      this.compsTableData.columns.push({text: "Seats", value: "seats"});
      this.willcallTableData.columns.push({text: "Seats", value: "seats"});
    }
  },
  computed: {
    navTabSelected() {
      const path = this.$route.path;

      if (path.endsWith("manual-entry")) {
        return 0;
      } else if (path.endsWith("bulk-upload")) {
        return 1;
      }
    }
  },
  methods: {
    onClickNavTab(index) {
      const path = this.$route.path;
      const url = this.$route.params.url;
      if (index === 0 && !path.endsWith("manual-entry")) {
        this.$router.push(`/comps/${url}/manual-entry`);
      } else if (index === 1 && !path.endsWith("bulk-upload")) {
        this.$router.push(`/comps/${url}/bulk-upload`);
      }
    },
    print(item) {
      if (item.ticketType == 'VIRTUAL') {
        if (item.magic_link) {
          this.$refs.magicLinkModal.link = item.magic_link;
          this.$refs.magicLinkModal.open();
        } else {
          this.$toast('Please download Comp CSV for virtual magic link');
        }
      } else {
        this.viewTickets(item);
      }
    },
    viewTickets(ticket) {
      let self = ticket;
      let comps = true;
      this.$axios
        .post(
          "/tickets/get-order-tickets-pdf",
          {
            //FIXME:: old api
            // order: self._id.$oid,
            order: self._id,
            timezone: self.timezone,
            comps: comps
          },
          {
            headers: {
              Accept: "application/pdf"
            },
            responseType: "blob"
          }
        )
        .then(({data}) => {
          setTimeout(() => {
            const blobPDF = new Blob([data], {
              type: "application/pdf"
            });
            const blobUrl = URL.createObjectURL(blobPDF);
            this.pdfData = blobUrl;
            this.pdfFileName = "comps-tickets.pdf";
          }, 500);
        });
    },
    viewReceipt(ticket, oldEvent) {
      this.$axios
        //FIXME:: old api
        // .get(`/comps/get-willcall-slip?order=${ticket._id.$oid}`, {
        .get(`/comps/get-willcall-slip?order=${ticket._id}`, {
          headers: {
            Accept: "application/pdf",
            "X-Whitelabel-Id": this.whiteLabelId
          },
          responseType: "blob"
        })
        .then(({data}) => {
          setTimeout(() => {
            const blobPDF = new Blob([data], {
              type: "application/pdf"
            });
            const blobUrl = URL.createObjectURL(blobPDF);
            this.pdfData = blobUrl;
            this.isWillCallSlip = true;
            this.pdfFileName = "willcall-slip.pdf";
          }, 500);
          // self.closeModal(2);
        });
    },
    addWillCall(ticket) {
      this.$refs.willcallTable.append(ticket);
    },
    addComp(ticket) {
      this.$refs.compsTable.append(ticket);
    },
    updateComps(comp) {
      this.$axios.post("/comps/update-comp", {
        //FIXME:: old api
        // eventId: comp.event_id.$oid,
        // compsId: comp._id.$oid,
        eventId: comp.event_id,
        compsId: comp._id,
        tierId: comp.tier_id,
        salesId: comp.saleDateID
      });
    },

    onLoad({dateID}) {
      // let self = this;
      // this.eventDateID = dateID;
      // this.$axios.get(`/comps/get-comps/${self.eventUrl}`).then(({ data }) => {
      //   this.oldEvent = data.data.event;
      //   this.fee = parseFloat(data.data.fee.$numberDecimal);
      //   // this.clearTable();
      // });
    }
  }
};
</script>

<style></style>
