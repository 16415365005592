<template>
  <TTDialog title="Magic Link" :active="active" v-on:close="close">
    <div class="info-text">Here is magic link:</div>
    <div class="link-section">
      <div class="link">{{ link }}</div>
    </div>
    <div class="modal-footer">
      <v-btn
        class="link-btn"
        :ripple="false"
        color="var(--primary)"
        dark
        @click="copyLink"
      >
        <font-awesome-icon icon="copy" />
        &nbsp; Copy to Clipboard
      </v-btn>
      <v-btn
        class="link-btn"
        :ripple="false"
        color="var(--primary)"
        dark
        @click="openLink"
      >
        Open Link
      </v-btn>
    </div>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";
import DiyoboInput from "@/components/DiyoboInput";
import DiyoboButton from "@/components/DiyoboButton";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { saveToClipboard } from "@/helpers/clipboard";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

library.add(faCopy);

export default {
  name: "magic-link-modal",
  components: {
    TTDialog,
    DiyoboInput,
    DiyoboButton,
    FontAwesomeIcon
  },
  data() {
    return {
      link: "",
      active: false,
    };
  },
  methods: {
    open() {
      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    copyLink() {
      saveToClipboard(this.link, this.$root.$refs.alert);
      this.$toast("Magic link copied!", { type: "success" });
      this.close();
    },
    openLink() {
      window.open(this.link, "_blank");
      this.close();
    },
    close() {
      this.active = false;

      setTimeout(() => {
        document.body.style.overflow = "";
        this.link = "";
      }, 500);
    }
  }
};
</script>
<style scoped lang="less">
.link-section {
  background-color: var(--content-dark);
  margin: 20px 0;
  padding: 5px 10px;
  border-radius: 8px;
}

.modal-footer {
  display: flex;
  justify-content: space-evenly !important;
  gap: 8px;
}

.link-btn {
  @media screen and (max-width: 850px) {
    font-size: 12px;
    font-weight: bold;
  }
}
</style>