<template>
  <div class="uploader-wrapper">
    <light-box
      :image="image"
      v-if="displayLightBox"
      v-on:close="displayImage(false)"
    />
    <div
      class="deletebtn"
      :class="{ hidden: !removalButton }"
      v-if="shouldIBeRemoved"
      :id="`delete-img-${indexToUse}`"
      v-on:click="deleteButton()"
    >
      <font-awesome-icon class="icon-rm" icon="times" />
    </div>
    <div class="input-mask" v-if="disabled"></div>
    <div class="small-img-wrapper">
      <label for="file" v-if="label" class="label"
        >{{ label }} <b v-if="required">*</b></label
      >
      <div class="small-img" :class="{ error: error, border: !coverUpload }">
        <div class="file-upload-container" :id="`file-container-${indexToUse}`">
          <div
            class="mask"
            v-on:click="
              heroImg(
                $event,
                `file-container-${indexToUse}`,
                `small-file-${indexToUse}`,
                `small-image-${indexToUse}`
              )
            "
            v-if="!coverUpload"
          ></div>
          <div class="mask cover" v-if="coverUpload"></div>
          <div
            class="image-border-wrapper"
            :id="`image-border-wrapper-${indexToUse}`"
            :class="{ active: imageFound }"
            v-on:click="displayImage(true)"
          >
            <img
              class="image-element"
              v-if="image"
              :src="image"
              :alt="imageName ? imageName : 'Uploaded Image'"
            />
            <span v-if="!image">
              <font-awesome-icon icon="images" />
            </span>
          </div>
          <input
            type="file"
            name="file"
            :id="`small-file-${indexToUse}`"
            class="img-upload"
            :accept="fileTypes ? fileTypes : 'image/*'"
          />
          <span
            :class="{ img_found: imageFound, empty_img: !imageFound }"
            :id="`text-span-${indexToUse}`"
            :displayMsg="displayMsg"
            for="file"
            >{{ displayMsg }}</span
          >
          <font-awesome-icon icon="upload" class="upload-icon" />
        </div>
      </div>
      <div class="error-msg" v-if="error">
        <div class="error-msg-inner">
          <font-awesome-icon class="icon" icon="exclamation-triangle" />
          <template v-if="typeof error === 'string'">{{ error }}</template>
          <template v-else>{{ label }} requires a valid input.</template>
        </div>
      </div>
      <small class="sublabel" v-else-if="sublabel">{{ sublabel }}</small>
    </div>
  </div>
</template>

<style lang="less" scoped>
.deletebtn {
  position: absolute;
  right: -15px;
  top: -15px;
  cursor: pointer;
}
.hidden {
  display: none;
}
.uploader-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  position: relative;

  .small-img-wrapper {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .label {
      display: block;
      flex-basis: 100%;
      margin-bottom: 0.5em;
      font-size: 16px;
      font-weight: 200;

      b {
        color: var(--new-green);
      }
    }
    .small-img {
      height: 50px;
      border: var(--img-uploader-border) solid 1px;
      background-color: var(--input-background);
      transition: 0.2s linear;
      border-radius: 5px;
      // transition: ;

      &.border {
        &:hover {
          border: rgba(91, 91, 91, 0.5) dashed 1px;
        }
      }

      .file-upload-container {
        position: relative;
        display: block;
        margin: auto;
        width: 100%;
        height: 100%;

        .mask {
          position: absolute;
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 1;

          &.cover {
            cursor: default;
            background-color: rgba(82, 82, 82, 0.5);
          }
        }

        .img-upload {
          display: none;
        }

        .image-border-wrapper {
          border: #807b7b dashed 2px;
          width: 40px;
          height: 35px;
          margin-left: 5px;
          text-align: center;
          color: #5b5b5b;
          display: inline-block;
          margin-top: 6px;
          position: absolute;
          border-radius: 2px;

          &.active {
            z-index: 2;
            border: unset;
          }

          .image-element {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          //end image element

          &::first-line {
            line-height: 35px;
          }
        }

        .upload-icon {
          margin-right: 7px;
          color: #4eb95e;
          position: absolute;
          right: 8px;
          top: 16px;
        }

        .empty_img {
          color: #5b5b5b;
          font-size: 16px;
          position: absolute;
          left: 10%;
          top: 30%;
          margin-left: 10px;
        }
        // end img-upload + label

        .img_found {
          color: var(--text);
          position: absolute;
          left: 10%;
          top: 30%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 6em);
          margin-left: 10px;
        }

        .img-icon {
          position: relative;
          //top: 34%;
          //left: -65px;
          padding-right: 8px;
          color: #5b5b5b;
          font-size: 20px;
        }
        // end img-icon
      }
      // end file-upload-container
    }
    .error {
      background-color: #1a0306 !important;

      &:focus {
        background-color: #47060f;
      }
    }

    .error-msg {
      position: relative;
      width: 100%;
      top: -1px;
      margin: 0 6px;
      border: 1px solid #1a0306;
      border-top: 1px solid transparent;
      border-radius: 0 0 5px 5px;
      background: #4e2e33;
      transition: 0.4s;

      .error-msg-inner {
        color: white;
        padding: 2px 5px 4px 5px;

        .icon {
          padding-right: 5px;
        }
      }
    }
    // end small-img
  }

  .input-mask {
    position: absolute;
    left: 0px;
    top: 29px;
    width: 100%;
    height: 52px;
    border-radius: 5px;
    background-color: rgba(82, 82, 82, 0.5);
  }
  // end small-img-wrapper
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faImages, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import LightBox from "./lightBox.vue";

library.add(faImages, faPlusSquare);
library.add(faTimes, faUpload);

export default {
  name: "tickettote-image-uploader",
  components: {
    FontAwesomeIcon,
    LightBox
  },
  props: {
    label: { type: String, default: "" },
    required: { type: Boolean },
    txt: String,
    type: String,
    placeholder: String,
    bus: Object,
    indexToInject: Number,
    error: Boolean | String,
    sublabel: String,
    info: Object,
    fileTypes: String,
    shouldIBeRemoved: Boolean,
    coverUpload: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      value: null,
      placetext: this.placeholder,
      image: this.info.image ? this.info.image : "",
      imageName: this.info.imageName ? this.info.imageName : "",
      imageFile: null,
      loaderReady: false,
      displayRemovalButton: this.info.image ? true : false,
      displayLightBox: false
    };
  },
  methods: {
    heroImg: function (e, fileContainer, fileInput, imgId) {
      let self = this;
      var heroImg = document.getElementById(fileInput);
      heroImg.click();
      this.value = null;

      document.getElementById(fileInput).onchange = function () {
        const reader = new FileReader();

        reader.onload = function () {
          self.image = reader.result;
        };

        if (this.files[0].size > 20 * 1024 * 1024) {
          self.$emit("error", "The file provided is larger than 20MB.");
        } else if (
          this.files[0].type !== "image/jpeg" &&
          this.files[0].type !== "image/jpg" &&
          this.files[0].type !== "image/png" &&
          this.files[0].type !== "image/gif"
        ) {
          self.$emit(
            "error",
            "The file provided is not a JPG, JPEG, PNG, or GIF."
          );
        } else {
          self.imageName = this.files[0].name;
          self.imageFile = this.files[0];
          self.removalButton = true;

          reader.readAsDataURL(this.files[0]);
        }
      };
    },
    deleteButton: function () {
      this.value = null;
      this.image = "";
      this.imageName = "";
      this.$emit("remove-image");
      this.removalButton = false;
    },
    applySpanSpacing() {
      let borderElement = document.querySelector(
        `#image-border-wrapper-${this.indexToUse}`
      );

      let width = borderElement.getBoundingClientRect().width;
      let leftPxAmount = parseInt(width) + 10;
      let spanElement = document.querySelector(`#text-span-${this.indexToUse}`);
      spanElement.style.left = `${leftPxAmount}px`;
    },
    displayImage(val) {
      this.displayLightBox = val;
    }
  },
  computed: {
    indexToUse() {
      if (this.indexToInject) {
        return this.indexToInject;
      } else {
        return 0;
      }
    },
    displayMsg() {
      if (this.imageName) {
        return this.imageName;
      } else {
        return "Upload Image";
      }
    },
    imageFound() {
      if (this.imageName) {
        return true;
      } else {
        return false;
      }
    },
    removalButton: {
      get() {
        if (this.shouldIBeRemoved) {
          return this.displayRemovalButton;
        } else {
          return false;
        }
      },
      set(val) {
        this.displayRemovalButton = val;
      }
    }
  },
  watch: {
    image(val) {
      if (val) {
        let objToSend = {
          image: this.image,
          imageName: this.imageName,
          imageFile: this.imageFile
        };
        this.$emit("data", objToSend);
      }
    }
  },
  mounted() {
    this.applySpanSpacing();
    if (this.bus) {
      this.bus.$on("image-upload-data", (args) => {
        this.$emit("data", {
          image: this.image,
          imageName: this.imageName,
          imageFile: this.imageFile
        });
      });

      this.bus.$on("delete-img", (args) => {
        this.deleteButton();
      });

      // one of these days we need to use props properly instead of this bullshit
      this.bus.$on("reload", () => {
        this.image = this.info.image;
        this.imageName = this.info.imageName;
        this.imageFile = this.info.imageFile;
      });
    }
  }
};
</script>
