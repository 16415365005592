var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EventManagementPage',{staticClass:"components",attrs:{"title":"Comps & Will Call","help-video":"https://youtube.com/embed/Xe1fuCogT_0","breadcrumbs":_vm.breadcrumbs},on:{"load":_vm.onLoad}},[_c('magic-link-modal',{ref:"magicLinkModal"}),_c('div',{staticClass:"page-tabs"},[_c('tab-tote',{attrs:{"tabs":_vm.navTabs,"value":_vm.navTabSelected},on:{"click":_vm.onClickNavTab}}),_c('div',{staticClass:"divider"})],1),_c('router-view',{attrs:{"url":_vm.url},on:{"addWillCall":_vm.addWillCall,"addComp":_vm.addComp}}),_c('DataTable',{ref:"compsTable",attrs:{"title":this.compsTableData.title,"endpoint":"/comps/get-comps/:event","args":{ event: _vm.eventUrl },"headers":this.compsTableData.columns,"custom-columns":['print', 'active']},scopedSlots:_vm._u([{key:"item.print",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.print(item)}}},[_vm._v("mdi-file-document")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"label":item.active ? 'Y' : 'N',"hide-details":""},on:{"change":function($event){return _vm.updateComps(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}}],null,true)}),_c('DataTable',{ref:"willcallTable",attrs:{"title":this.willcallTableData.title,"endpoint":"/comps/get-willcall/:event","args":{ event: _vm.eventUrl },"headers":this.willcallTableData.columns,"custom-columns":['slip', 'print']},scopedSlots:_vm._u([{key:"item.slip",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.viewReceipt(item)}}},[_vm._v("mdi-receipt-text")])]}},{key:"item.print",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.print(item)}}},[_vm._v("mdi-file-document")])]}}],null,true)}),(_vm.pdfData)?_c('PDFViewer',{attrs:{"src":_vm.pdfData,"file-name":_vm.pdfFileName,"invoice":_vm.isWillCallSlip},on:{"close":function($event){_vm.pdfData = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }