<template>
  <div id="light-box" class="light-box">
    <div class="main">
      <div class="close">
        <h1 v-on:click="$emit('close')">&times;</h1>
      </div>
      <div class="image-wrapper">
        <img class="image light-box-image" :src="lightBoxImg" />
      </div>
    </div>
  </div>
</template>

<style lang='less' scoped>
.light-box {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000000;
  z-index: 100;
  top: 0px;
  left: 0px;

  .main {
    display: flex;
    flex-direction: column;
    width: 100%;

    .close {
      width: 100%;
      height: 200px;
      font-size: 25px;
      position: relative;

      h1 {
        cursor: pointer;
        //margin-right: 170px;
        align-items: center;
        display: flex;
        font-size: 62px !important;
        color: #ffffff;
        position: absolute;
        //right: 200px;
        top: 80px;
      }
    }

    .image-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;

      .image {
        max-height: 700px;
        max-width: 1500px;
      }
    }

    .controls {
      display: flex;
      justify-content: center;
      width: 100%;

      .arrow {
        margin: 50px 10px;
        font-size: 25px;
        cursor: pointer;
      }
    }
  }
}
</style>

<script>
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
export default {
  props: {
    image: String,
  },
  computed: {
    lightBoxImg() {
      return this.image;
    },
  },
  methods: {
    applyXPositions() {
      let imageEle = document.querySelector(".light-box-image");
      let bodyRight = document.body.getBoundingClientRect().right;
      console.log(bodyRight, "lets try something crazy");
      let imageRight = imageEle.getBoundingClientRect().right;
      let rightPxAmount = bodyRight - imageRight - 60;
      let closeEle = document.querySelector(".close").querySelector("h1");
      closeEle.style.right = `${rightPxAmount}px`;
    },
  },
  beforeMount() {
    let targetElement = document.querySelector("#light-box");
    disableBodyScroll(targetElement);
  },
  mounted() {
    this.applyXPositions();
  },
  beforeDestroy() {
    let targetElement = document.querySelector("#light-box");
    enableBodyScroll(targetElement);
  },
};
</script>