<template>
  <div class="ticket-tier-selector">
    <div class="row">
      <div
        class="col"
        :class="
          !local.type ||
          local.tierID === 'All Ticket Tiers' ||
          hasVenueAccessTimes
            ? 'full'
            : 'half'
        "
      >
        <FormulateInput
          type="select"
          label="Ticket Tier"
          name="tier"
          placeholder="Select Ticket Tier"
          validation="required"
          :disable-errors="isHandlingErrors && !error"
          :error-behavior="isHandlingErrors ? 'live' : 'blur'"
          :options="types"
          :disabled="readOnly"
          v-model="local.tierID"
          @selected="updateTierID"
        />
      </div>
      <div v-if="hasSaleDates" class="col half">
        <FormulateInput
          type="select"
          name="salesId"
          label="Sales Date Inventory"
          placeholder="Select Sale Date"
          :disable-errors="isHandlingErrors && !error"
          :error-behavior="isHandlingErrors ? 'live' : 'blur'"
          :validation="!optionals && 'required'"
          :options="saleDates[local.tierID]"
          v-model="local.saleDateID"
          @selected="updateSaleDateID"
          :disabled="readOnly"
        />
      </div>
      <div v-if="hasVenueAccessDates" class="col half">
        <FormulateInput
          type="select"
          label="Date"
          placeholder="Select Date"
          :disable-errors="isHandlingErrors && !error"
          :error-behavior="isHandlingErrors ? 'live' : 'blur'"
          :validation="!optionals && 'required'"
          :options="venueAccessDates[local.tierID]"
          v-model="local.venueAccessDateID"
          @selected="updateVenueAccessDate"
          :disabled="readOnly"
        />
      </div>
      <div v-if="hasVenueAccessTimes" class="col half">
        <FormulateInput
          type="select"
          label="Time"
          placeholder="Select Time"
          :disable-errors="isHandlingErrors && !error"
          :error-behavior="isHandlingErrors ? 'live' : 'blur'"
          :validation="!optionals && 'required'"
          :disabled="!local.venueAccessDateID || readOnly"
          :options="venueAccessTimes[local.venueAccessDateID]"
          v-model="local.venueAccessTimeID"
          @selected="updateVenueAccessTime"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "TicketTierSelector",
  props: {
    tiers: {
      type: Array,
      default: () => [],
    },
    readOnly: { type: Boolean, default: false },
    value: Object,
    error: Boolean,
    optionals: Boolean,
    hasAllTiers: Boolean,
    willCall: Boolean,
    allTiers: {
      type: Boolean, default: false
    }
  },
  computed: {
    hasSaleDates() {
      return (
        this.local.type === "General Tier" &&
        this.local.tierID !== "All Ticket Tiers"
      );
    },
    hasVenueAccessDates() {
      return (
        (this.local.type && this.local.type !== "General Tier") ||
        this.local.venueAccessDateID
      );
    },
    hasVenueAccessTimes() {
      return (
        this.local.type === "Date and Time" || this.local.venueAccessTimeID
      );
    },
    isHandlingErrors() {
      return typeof this.error === "boolean";
    },
    types() {
      const tiers = this.tiers.map((t) => ({
        label: t.name,
        value: t.id,
        type: t.type,
        price: t.price,
        ticketType: t.ticketType,
      }));
      if(this.allTiers){
        return tiers;
      }

      if (!this.willCall) {
        const newTiers = tiers.filter(
          (t) => t.ticketType !== "DONATION" && t.price > 0
        );

        if (this.hasAllTiers) {
          newTiers.unshift({
            label: "All Ticket Tiers",
            value: "All Ticket Tiers",
          });
        }

        return newTiers;
      } else {
        const newTiers = tiers.filter(
          (t) =>
            t.ticketType !== "DONATION" &&
            t.price > 0 &&
            t.ticketType !== "VIRTUAL"
        );

        if (this.hasAllTiers) {
          newTiers.unshift({
            label: "All Ticket Tiers",
            value: "All Ticket Tiers",
          });
        }

        return newTiers;
      }
    },
    saleDates() {
      return this.tiers.reduce((a, t) => {
        a[t.id] = t.saleDates.map((d) => ({
          label: dayjs(+d.sale_start.$date.$numberLong).format(
            "ddd, MMM DD, YYYY"
          ),
          value: d.id,
        }));
        return a;
      }, {});
    },
    venueAccessDates() {
      let venueAccessDates = {};
      this.tiers.filter((t) => Array.isArray(t.venueAccessDates))
          .map((t) => {
            let date = [];
            t.venueAccessDates.map((v) => {
              if (v.enabled) {
                date.push({
                  label: dayjs(+v.start.$date.$numberLong).format("ddd, MMM DD, YYYY"),
                  value: v._id,
                });
              }
            })
            if (date) {
              venueAccessDates[t.id] = date;
            }
          });
      return venueAccessDates;
    },
    venueAccessTimes() {
      return this.tiers
        .filter(
          (t) => Array.isArray(t.venueAccessDates) && t.type === "Date and Time"
        )
        .flatMap((t) => t.venueAccessDates)
        .reduce((a, d) => {
          a[d.id] = d.times.map((t) => ({
            label: dayjs(+t.start).format("h:mm A"),
            value: t.id,
          }));
          return a;
        }, {});
    },
    local() {
      return this.value  && this.value !== ''
        ? this.value
        : {
            tierID: null,
            saleDateID: null,
            ticketType: null,
            venueAccessDateID: null,
            venueAccessTimeID: null,
            type: null,
            invalid: true,
          };
    },
  },
  watch: {
    value(value) {
      if (value === null) {
        this.$forceUpdate();
      }
    },
  },
  methods: {
    updateTierID(value) {
      let type = null;
      let name = null;

      if (value === "All Ticket Tiers") {
        type = "General Tier";
        name = "All Ticket Tiers";
      } else if (value) {
        const tier = this.types.find((t) => t.value === value);
        this.local.ticketType = tier.ticketType;
        type = tier.type;
        name = tier.label;
      }

      this.$emit("input", {
        ...this.local,
        tierID: value,
        type,
        name,
        saleDateID: null,
        // kind of a hacky solution to having to select the same date twice for tiers
        venueAccessDateID: null,
        venueAccessTimeID: null,
        invalid: !this.optionals && value !== "All Ticket Tiers",
      });
    },
    updateSaleDateID(value) {
      if (!value) {
        return;
      }

      this.$emit("input", {
        ...this.local,
        saleDateID: value,
        venueAccessDateID: null,
        venueAccessTimeID: null,
        invalid: false,
      });
    },
    updateVenueAccessDate(value) {
      if (!value) {
        return;
      }

      this.$emit("input", {
        ...this.local,
        venueAccessDateID: value,
        saleDateID: null,
        venueAccessTimeID: null,
        invalid: !this.optionals && this.local.type !== "Date",
      });
    },
    updateVenueAccessTime(value) {
      if (!value) {
        return;
      }

      this.$emit("input", {
        ...this.local,
        venueAccessTimeID: value,
        saleDateID: null,
        invalid: !this.optionals && this.local.type !== "Date and Time",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ticket-tier-selector {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
</style>
