<template>
  <div class="pdf-viewer">
    <div class="content-wrapper">
      <div class="top-bar">
        <div class="content">
          <div class="left">
            <!-- <font-awesome-icon
              icon="search-minus"
              @click="scale -= scale > 0.2 ? 0.1 : 0"
            /> -->
            <span class="value">{{ formattedZoom }}%</span>
            <!-- <font-awesome-icon
              icon="search-plus"
              @click="scale += scale < 2 ? 0.1 : 0"
            /> -->
          </div>
          <div class="middle">
            <div class="pages">
              <font-awesome-icon icon="chevron-left" @click="pageBack" />
              <span class="value">
                {{ page }} / {{ pdfPageCount ? pdfPageCount : "∞" }}
              </span>
              <font-awesome-icon icon="chevron-right" @click="pageForward" />
            </div>
          </div>
          <div class="right">
            <font-awesome-icon icon="download" @click="download" />
            <font-awesome-icon icon="times" @click="$emit('close')" />
          </div>
        </div>
      </div>
      <!-- <ClientOnly> -->

      <div v-if="site === 'buyers'">
        <pdf
          class="pdf-view pdf-page page-one"
          :class="{ invoice: invoice }"
          :source="pdfdata"
          :page="1"
          :id="1"
          :width="width"
          @num-pages="pdfPageCount = $event"
        ></pdf>
        <div
          class="pdf-view"
          v-if="pdfPageCount > 1"
          :class="{ invoice: invoice }"
        >
          <div v-for="(pageNum, index) in pdfPageCount" :key="index">
            <pdf
              v-if="index > 0"
              class="pdf-page"
              :source="pdfdata"
              :page="pageNum"
              :width="width"
              :key="index"
              :id="pageNum"
            ></pdf>
          </div>
        </div>
      </div>
      <div class="pdf-view" :class="{ invoice: invoice }" v-else>
        <pdf
          class="pdf-view pdf-page page-one"
          :class="{ invoice: invoice }"
          :src="pdfdata"
          :page="1"
          :id="1"
          @num-pages="pdfPageCount = $event"
        ></pdf>
        <div
          class="pdf-view"
          :class="{ invoice: invoice }"
          v-if="pdfPageCount > 1"
        >
          <div v-for="(pageNum, index) in pdfPageCount" :key="index">
            <pdf
              v-if="index > 0"
              class="pdf-page"
              :src="pdfdata"
              :page="pageNum"
              :key="index"
              :id="pageNum"
            ></pdf>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faSearchMinus,
  faSearchPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faChevronLeft,
  faChevronRight,
  faDownload,
  faSearchMinus,
  faSearchPlus,
  faTimes
);

export default {
  name: "pdf-viewer",
  components: {
    FontAwesomeIcon,
  },
  props: {
    src: String,
    fileName: String,
    numPages: Number,
    invoice: Boolean,
  },
  data() {
    return {
      page: 1,
      pdfdata: undefined,
      errors: [],
      scale: 1,
      pdfPageCount: 0,
      changePage: null,
      // numPages: undefined
    };
  },
  computed: {
    formattedZoom() {
      return Number.parseInt(this.scale * 100);
    },
    site() {
      return this.$store.state.site;
    },
    width() {
      if (window.innerWidth < 900) {
        return 400;
      }
      return 800;
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      document.body.style.overflow = "hidden";
    },
    enter(el) {
      el.style.opacity = 1;
    },
    leave(el) {
      el.style.opacity = 0;
      document.body.removeAttribute("style");
    },
    handle_pdf_link: function(params) {
      // Scroll to the appropriate place on our page - the Y component of
      // params.destArray * (div height / ???), from the bottom of the page div
      var page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    getPdf() {
      let self = this;
      this.pdfdata = this.src;

      function changePage() {
        var i = 1,
          count = Number(this.pdfPageCount);
        if (count <= 1) {
          return;
        }
        do {
          if (
            self.$el.scrollTop >= self.findPos(document.getElementById(i)) &&
            self.$el.scrollTop <= self.findPos(document.getElementById(i + 1))
          ) {
            this.page = i;
          }
          i++;
        } while (i < count);
        if (
          self.$el.scrollTop + 110 >=
          self.findPos(document.getElementById(i))
        ) {
          this.page = i;
        }
      }
      this.$el.addEventListener("scroll", changePage);
      this.changePage = changePage;
    },
    findPos(obj) {
      if(!obj) {
        return 0;
      }
      return obj.offsetTop + 50;
    },
    pageBack() {
      if (this.page > 1) {
        console.log(this.page);
        this.page--;
        document.getElementById(this.page).scrollIntoView();
        // window.scrollBy(0, -80);
      }
    },
    pageForward() {
      if (this.page < this.numPages) {
        this.page++;
        document.getElementById(this.page).scrollIntoView();
        // window.scrollBy(0, -80);
      }
    },
    download() {
      const link = document.createElement("a");

      link.href = this.src;
      link.download = this.fileName || "file.pdf";
      link.click();
    },
  },
  mounted() {
    this.getPdf();

    document.querySelector("#app .banner").style.visibility = "hidden";
    document.querySelector("#app .navbar").style.visibility = "hidden";
    document.body.style.overflow = "hidden";
  },
  destroyed() {
    document.querySelector("#app .banner").style.visibility = "visible";
    document.querySelector("#app .navbar").style.visibility = "visible";
    document.body.removeAttribute("style");

    this.$el.removeEventListener("scroll", this.changePage);
  },
};
</script>

<style lang="less" scoped>
.pdf-viewer {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.8);

  .content-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    flex-direction: column;

    .top-bar {
      display: flex;
      position: fixed;
      width: 100%;
      height: 75px;
      top: 0;
      background-color: @colors[navbar-background];
      z-index: 100;
      box-shadow: 0px 3px 8px -2px rgba(0, 0, 0, 0.3);

      .content {
        display: flex;
        margin: auto;
        padding: 0 20px;
        justify-content: space-between;
        width: 100%;
        max-width: 1100px;
        font-size: 24px;

        .left {
          display: flex;
          width: 25%;
          align-items: center;

          .value {
            margin: 0 10px;
          }
        }

        .middle {
          display: flex;
          width: 50%;
          justify-content: center;
          align-items: center;

          .pages {
            display: flex;
            align-items: center;

            .value {
              margin: 0 10px;
            }

            .fa-chevron-left {
              cursor: pointer;
            }

            .fa-chevron-right {
              cursor: pointer;
            }
          }
        }

        .right {
          display: flex;
          width: 25%;
          justify-content: flex-end;
          align-items: center;

          .fa-download {
            margin-right: 16px;
            cursor: pointer;
          }

          .fa-times {
            font-size: 30px;
            cursor: pointer;
          }

          @media screen and (max-width: 1250px) {
            margin-right: 50px;
          }
        }
      }
    }
    .pdf-view {
      max-width: 800px;

      &.page-one {
        margin-top: 80px;
      }

      &.invoice {
        width: 130%;
      }

      // scroll-margin-top: 75px;

      // &:nth-last-child(1) {
      //   margin-bottom: 0;
      // }
    }
    .pdf-page {
      scroll-margin-top: 0px; //80
      margin-bottom: 0px; //30
    }
  }
}
</style>
